import React, { useEffect } from 'react'
import { Auth, Articles } from '../../context'
import { Button, Text, Pill, Input } from '../atoms'
import { useNavigate } from 'react-router-dom'

const ArticlesTable = () => {
    const navigate = useNavigate()
    const { isLogged } = Auth.useAuth()
    const { articles, getArticles, updateDraft } = Articles.useArticles()

    useEffect(() => {
        if (isLogged) {
            getArticles()
        }
    }, [])

    const handleDetails = async (id) => {
        navigate(`/articles/${id}`)
    }

    const handleDraftChange = async (id, isChecked) => {
        await updateDraft(id, { isDraft: !isChecked });
    };

    return (
        <div>
            <div className="flex justify-center">
                <Button.Primary
                    onClick={async () => {
                        navigate('/articles/create')
                    }}
                >
                    Créer un article
                </Button.Primary>
            </div>
            <div className="py-6 ml-12">
                <Text.h2>Liste des articles</Text.h2>
            </div>
            {articles && typeof articles === 'object' && articles.length > 0 ? (
                <table className="w-full divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="w-6/12 px-6 py-8 text-left">
                                Titre
                            </th>
                            <th scope="col" className="w-3/12 px-6 py-8 text-left">
                                Tags
                            </th>
                            <th scope="col" className="w-2/12"></th>
                            <th scope="col" className="w-1/12"></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y">
                        {articles.map((article) => (
                            <tr key={article._id}>
                                <td className="px-6 py-8">{article.title}</td>
                                <td className="space-y-3 px-6 py-8">
                                    {article.tags.map((tag, index) => (
                                        <Pill.Tag key={index}>{tag}</Pill.Tag>
                                    ))}
                                </td>
                                <td className="flex justify-center py-8">
                                    <Button.Primary onClick={() => handleDetails(article._id)}>
                                        Voir plus
                                    </Button.Primary>
                                </td>
                                <td>
                                <Input.Swipe
                                        checked={!article.isDraft}
                                        onChange={(isChecked) => handleDraftChange(article._id, isChecked)}
                                        label={article.isDraft ? "Brouillon" : "Public"}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="p-12">Chargement des articles...</p>
            )}
        </div>
    )
}

export default ArticlesTable
