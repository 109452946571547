import React from 'react'
import { Icon, Text } from '../atoms'
import { useNavigate } from 'react-router-dom'

const ArrowBack = () => {
    const navigate = useNavigate()

    return (
        <div className="flex items-center h-16 w-fit" onClick={() => navigate('/')}>
            <Icon.ArrowBack className="w-5 mr-2" />
            <Text.Clickable>Accueil</Text.Clickable>
        </div>
    )
}

export default ArrowBack
