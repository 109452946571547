import React, { useEffect } from 'react'

import { Routes, Route, useNavigate } from 'react-router-dom'

import { UserDetails } from '../templates'
import { Users } from '../../context'
import { Table } from '../organisms'

const User = () => {
    const navigate = useNavigate()
    const { userSelected } = Users.useUsers()

    useEffect(() => {
        if (userSelected) {
            navigate('/user/details')
        } else {
            navigate('/user')
        }
    }, [userSelected])

    return (
        <Routes>
            <Route path="/" element={<Table />} />
            <Route path="/details" element={<UserDetails />} />
        </Routes>
    )
}

export default User
