import React from 'react'
import { Select, SelectItem } from '@nextui-org/react'

const Dropdown = ({ value, options, setValue, labelValue }) => {
    return (
        <div className="relative grid gap-1">
            <label className="ml-2 text-lg font-medium">{labelValue}</label>
            <Select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="px-6 border border-black rounded-full"
                classNames={{
                    listbox: ['border', 'border-black', 'rounded-3xl', 'p-3', 'w-full', 'bg-white']
                }}
            >
                {options.map((option) => (
                    <SelectItem value={option.value} key={option.value} classNames={{ base: ['data-[selected=true]:bg-light-gray', 'rounded-lg', 'p-3'], }} >
                        {option.label}
                    </SelectItem>
                ))}
            </Select>
        </div>
    )
}

export default Dropdown
