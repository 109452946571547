/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useEffect, useState } from 'react'

import { useRequest } from '../request'
import { useAuth } from '../auth'

const ArticlesContext = createContext({
    articles: Array,
    details: Object,
    getArticles: async () => {},
    getArticleDetails: async () => {},
    saveArticle: (title, content, seo, tags) => {},
    deleteArticle: async () => {},
    addArticle: async (title, content, seo, tags, isDraft) => {},
    updateDraft: async (id, isDraft) => {},
    uploadImage: async(file) => {},
    deleteImage: async(imageId) => {}
})

export function ArticlesContextProvider({ children }) {
    const { handleRequest } = useRequest()
    const { token } = useAuth()

    const [articles, setArticles] = useState([])

    const getArticles = async () => {
        setArticles([])
        const response = await handleRequest('get', `admin/articles`, null, token)

        if (response?.data) {
            setArticles(response.data.article)
        }
    }

    const addArticle = async (title, content, seo, tags, isDraft, image) => {
        const response = await handleRequest(
            'post',
            'admin/articles',
            { title: title, content: content, seo: seo, tags: tags, isDraft: isDraft, image: image },
            token
        )
    
        if (response.data) {
            const newArticle = response.data.article
            setArticles([...articles, newArticle])
        }
    }

    const updateDraft = async (id, isDraft) => {
        const response = await handleRequest(
            'patch',
            `admin/articles/${id}/draft`,
            isDraft,
            token
        )
    
        if (response.data) {
            const articleUpdated = response.data?.article

            let articlesUpdated = [...articles]
            articles.map((article, index) => {
                if (article._id === articleUpdated._id) {
                    articlesUpdated[index] = articleUpdated
                }
            })

            setArticles(articlesUpdated)
        }
        return
    }

    const saveArticle = async (id, title, content, seo, tags, image) => {
        const response = await handleRequest(
            'put',
            `admin/articles/${id}`,
            { title, content, seo, tags, image },
            token
        )

        if (response.data) {
            const articleUpdated = response.data?.article
            const articlesUpdated = articles.map(article => 
                article._id === articleUpdated._id ? articleUpdated : article
            )
            
            setArticles(articlesUpdated)
        }
        return
    }

    const getArticleDetails = async (id) => {
        const response = await handleRequest('get', `admin/articles/${id}`, null, token)

        if (response?.data) {
            return response.data.article
        } else {
            return {title: "", content: [], seo: "", tags: []}
        }
    }


    const deleteArticle = async (id) => {
        await handleRequest('delete', `admin/articles/${id}`, {}, token)
    }

    const uploadImage = async (file, fileName) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
    
            const response = await handleRequest('POST', 'image', formData, token, "multipart/form-data");
    
            if (!response || response.error) {
                throw new Error(`Failed to upload image '${fileName}'`);
            }
    
            const imageData = response.data.image;
            return imageData;
        } catch (error) {
            console.error(`Error uploading image '${fileName}':`, error);
            throw error;
        }
    };

    const deleteImage = async (imageId) => {
        try {
            const response = await handleRequest('delete', `image/${imageId}`, {}, token);
            if (!response || response.error) {
                throw new Error(`Failed to delete image with ID '${imageId}'`);
            }
            console.log(`Image with ID '${imageId}' deleted successfully.`);
        } catch (error) {
            console.error(`Error deleting image with ID '${imageId}':`, error);
            throw error;
        }
    };
    

    return (
        <ArticlesContext.Provider
            value={{
                articles: articles,
                getArticles: getArticles,
                getArticleDetails: getArticleDetails,
                saveArticle: saveArticle,
                deleteArticle: deleteArticle,
                addArticle: addArticle,
                updateDraft: updateDraft,
                uploadImage: uploadImage,
                deleteImage: deleteImage,
            }}
        >
            {children}
        </ArticlesContext.Provider>
    )
}

export const useArticles = () => useContext(ArticlesContext)
