import React from 'react'

import { Users } from '../../context'
import { Text } from '../atoms'
import dayjs from 'dayjs'

const Usage = ({ lastUseAt }) => {
    const { details } = Users.useUsers()

    return (
        <div className="grid gap-5">
            <Text.h2>Utilisation</Text.h2>
            <div className="grid gap-3">
                <Text.Paragraph.Normal>
                    Dernière utilisation :{' '}
                    <span className="font-bold">{dayjs(lastUseAt).format('dddd DD MMM YYYY')}</span>
                </Text.Paragraph.Normal>
                <Text.Paragraph.Normal>
                    <span className="font-bold">{details?.bookings?.length}</span> rendez-vous sur les 2 dernier mois
                </Text.Paragraph.Normal>
                <Text.Paragraph.Normal>
                    Utilisation moyenne par jour :{' '}
                    <span className="font-bold">{Math.floor(details?.historics?.length / 60)}</span>
                </Text.Paragraph.Normal>
            </div>
        </div>
    )
}

export default Usage
