export const getLocalStorageValue = (item) => {
    return window.localStorage.getItem(item)
}

export const setLocalStorageValue = (item, value) => {
    window.localStorage.setItem(item, value)
    return
}

export const getSessionStorageValue = (item) => {
    return window.sessionStorage.getItem(item)
}

export const setSessionStorageValue = (item, value) => {
    return window.sessionStorage.setItem(item, value)
}
