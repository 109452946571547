import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useRequest } from '../request'
import { isGoodPassword, persistStorage, getAuthStored, isTokenExpired } from './utils'
import { setLocalStorageValue } from '../storage'

/* eslint no-unused-vars: 0 */
const AuthContext = createContext({
    id: String,
    profil: String,
    email: String,
    type: String,
    firstName: String,
    lastName: String,
    customer: String,
    token: String,
    needNewPassword: Boolean,
    isOpenCguCgv: String,
    restoredd: false,
    handleLoginClassic: async (email, password) => {
        return Promise.resolve()
    },
    handleLoginToken: async (token) => {
        return Promise.resolve()
    },
    handleSignup: async (sexe, firstName, lastName, email, address, phoneNumber) => {
        return Promise.resolve()
    },
    handleLogout: () => {},
    getNewPassword: async (email) => {
        return Promise.resolve()
    },
    handleChangePassword: async (actualPassword, newPassword, confirmPassword) => {
        return Promise.resolve()
    },
    isLogged: () => {
        return false
    },
    handleDelete: async () => {},
})

export function AuthContextProvider({ children }) {
    const navigate = useNavigate()
    const { handleRequest, setMessage } = useRequest()

    const [id, setId] = useState()
    const [profil, setProfil] = useState()
    const [email, setEmail] = useState()
    const [type, setType] = useState('client')
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [customer, setCustomer] = useState()
    const [password, setPassword] = useState()

    const [token, setToken] = useState()
    const [needNewPassword, setNeedNewPassword] = useState(false)

    const [restored, setRestored] = useState(false)
    const [isOpenCguCgv, setIsOpenCguCgv] = useState('')

    useEffect(() => {
        if (isTokenExpired()) {
            const stored = JSON.parse(localStorage.getItem('auth'))
            if (stored) {
                refreshToken(stored?.email)
            }
        }
        const stored = getAuthStored()
        if (stored) {
            setId(stored.id)
            setProfil(stored.profil)
            setEmail(stored.email)
            setType(stored.type)
            setFirstName(stored.firstName)
            setLastName(stored.lastName)
            setCustomer(stored.customer)
            setToken(stored.token)
            setNeedNewPassword(stored.needNewPassword)
            setPassword(stored.password)
        }
        setRestored(true)

        return () => {}
    }, [])

    const refreshToken = async (email) => {
        const response = await handleRequest(
            'post',
            'auth/refresh',
            {
                email: email,
            },
            null,
            null,
            true
        )

        if (response?.data?.user?.token) {
            const jsonify = {
                id: id,
                profil: profil,
                email: email,
                type: type,
                firstName: firstName,
                lastName: lastName,
                customer: customer,
                token: response.data.user.token,
                needNewPassword: needNewPassword,
                password: password,
            }
            setToken(response.data.user.token)
            setLocalStorageValue('auth', JSON.stringify(jsonify))
        }

        return response?.data?.user?.token
    }

    const getUser = async (email) => {
        const response = await handleRequest(
            'post',
            'auth',
            {
                email: email,
            },
            null,
            null,
            true
        )

        return response?.data?.user
    }

    const authUser = async (id, email) => {
        const response = await handleRequest(
            'post',
            `auth/login/${id}`,
            {
                email: email,
            },
            null,
            null,
            true
        )

        return response?.data?.user
    }

    const handleLoginClassic = async (email, password) => {
        const user = await getUser(email)

        if (user) {
            const goodPassword = await isGoodPassword(password, user.password)

            if (goodPassword && user.type === 'admin') {
                const userAuthenticated = await authUser(user._id, email)

                setId(userAuthenticated._id)
                setProfil(userAuthenticated._profil)
                setEmail(email)
                setFirstName(userAuthenticated.prenom)
                setLastName(userAuthenticated.nom)
                setType(userAuthenticated.type)
                setToken(userAuthenticated.token)
                setCustomer(userAuthenticated.customer)
                setNeedNewPassword(userAuthenticated.needNewPassword ? true : false)
                setPassword(user.password)

                persistStorage(
                    userAuthenticated._id,
                    userAuthenticated._profil,
                    email,
                    userAuthenticated.type,
                    userAuthenticated.prenom,
                    userAuthenticated.nom,
                    userAuthenticated.customer,
                    userAuthenticated.token,
                    userAuthenticated.needNewPassword,
                    user.password
                )
            } else {
                setMessage({
                    type: 'warning',
                    message: "La combinaison email / mot de passe n'est pas bonne.",
                })
            }
        } else {
            setMessage({
                type: 'warning',
                message: 'Aucun compte trouvé avec cet email.',
            })
        }

        return
    }

    const isLogged = () => {
        if (id && token && type === 'admin') {
            return true
        }
        return false
    }

    const handleLogout = () => {
        setId()
        setId()
        setProfil()
        setEmail()
        setType('client')
        setFirstName()
        setLastName()
        setCustomer()
        setToken()
        setNeedNewPassword(false)
        setPassword()
        localStorage.clear()
        sessionStorage.clear()
        navigate('/')
    }

    return (
        <AuthContext.Provider
            value={{
                id: id,
                profil: profil,
                email: email,
                type: type,
                firstName: firstName,
                lastName: lastName,
                customer: customer,
                token: token,
                needNewPassword: needNewPassword,
                restored: restored,
                isOpenCguCgv: isOpenCguCgv,
                setIsOpenCguCgv: setIsOpenCguCgv,
                handleLoginClassic: handleLoginClassic,
                isLogged: isLogged,
                handleLogout: handleLogout,
            }}
        >
            {children}{' '}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
