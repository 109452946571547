import React from 'react'
import { ArrowBack, Icon, Text } from '../atoms'

import logo from '../../assets/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className="grid items-center grid-cols-3 p-2">
            {location.pathname === '/' ? (
                <img
                    src={logo}
                    alt="Iléa App"
                    className="rounded w-14 h-14"
                    onClick={() => navigate('/', { state: { from: '/user' } })}
                />
            ) : (
                <ArrowBack nav="/" />
            )}
            <Text.h2 className="mx-auto">
                {location.pathname === '/' && 'Dashboard'}
                {location.pathname.indexOf('user') !== -1 && 'Utilisateurs'}
                {location.pathname.indexOf('prestation') !== -1 && 'Prestations'}
                {location.pathname.indexOf('article') !== -1 && 'Articles'}
            </Text.h2>
        </div>
    )
}

const User = ({ avatar, firstName, lastName, goBack }) => {
    return (
        <div className="flex items-center p-2 space-x-4">
            <div onClick={() => goBack()}>
                <Icon.ArrowBack className="w-5" />
            </div>
            <div className="flex items-center space-x-2">
                <img src={avatar} alt="Photo de profil" className="rounded-full w-14 h-14" />
                <Text.h2 className="mx-auto">
                    {firstName} {lastName}
                </Text.h2>
            </div>
        </div>
    )
}

export { Home, User }
