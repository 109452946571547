import React from 'react'
import { CardButton } from '../molecules'
import { useNavigate } from 'react-router-dom'

const Home = () => {

    const navigate = useNavigate()

    return (
        <div className="mx-12 mt-12">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
                <CardButton onClick={async () => {navigate('/user')}}>Utilisateur</CardButton>
                <CardButton onClick={async () => {navigate('/prestations')}}>Prestations</CardButton>
                <CardButton onClick={async () => {navigate('/articles')}}>Articles</CardButton>
            </div>
        </div>
    )
}

export default Home
