import axios from 'axios'

const proxy = `${process.env.REACT_APP_API_URL}/api/v1`

export const request = async (method, path, body, token, contentType) => {
    const config = {
        method: method,
        url: `${proxy}/${path}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Content-Type': contentType || 'application/json',
            'Access-Control-Allow-Credentials': true,
            'x-access-token': token,
            withCredentials: true,
        },
        data: body,
    }

    return await axios(config)
        .then((response) => {
            return { error: false, data: response.data, disconnected: false }
        })
        .catch((error) => {
            // Disconnect if wrong token
            if (error.response) {
                if (error.response.status === 401 || error.response.statusText === 'Unauthorized') {
                    return {
                        error: true,
                        disconnected: true,
                        data: error.response.data,
                    }
                }
            }
            return {
                error: true,
                data: error.response?.data || error,
                disconnected: false,
            }
        })
}

export const cleanCache = (requestsValue) => {
    let cache = requestsValue
    let cacheArray = Object.keys(requestsValue)

    if (cacheArray.length > 0) {
        cacheArray.splice(0, cacheArray.length - 1)
        cache = {}
        cacheArray.forEach((request) => {
            cache[request] = requestsValue[request]
        })
    }

    return cache
}

export const createKey = (method, path) => {
    return `${method}/${path}`
}

export const createObject = (token, body, response) => {
    return {
        token: token,
        body: body,
        response: response,
    }
}

export const addRequestToCache = (oldValues, key, object) => {
    const cache = oldValues
    cache[key] = object

    return cache
}
