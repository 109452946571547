import React from 'react'
import { Text } from '../atoms'

export const ComposantRender = ({ type, content, style }) => {

    switch (type) {
        case 'h1':
            return <div style={style}><Text.h1 style={style}>{content}</Text.h1></div>
        case 'h2':
            return <div style={style}><Text.h2>{content}</Text.h2></div>        
        case 'h3':
            return <div style={style}><Text.h3 style={style}>{content}</Text.h3></div>
        case 'p':
            return <div style={style}><Text.Normal style={style} className="whitespace-pre-line">{content}</Text.Normal></div>
        case 'img':
            return (
                <div style={{ maxHeight: '500px' }} className='flex w-full'>
                    <img className='flex w-full object-cover object-top rounded-[50px]' src={`${content}`} alt={`${content}`} />
                </div>
            )
        default:
            return <Text.Normal style={style}>{content}</Text.Normal>
    }
}
