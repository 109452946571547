import React, { useEffect, useState } from 'react'
import { Auth, Users } from '../../context'
import { Button } from '../atoms'

const Table = () => {
    const { isLogged } = Auth.useAuth()
    const { users, getMoreUsers, selectUser } = Users.useUsers()

    const [usersBefore, setUsersBefore] = useState([])
    const [canLoadMore, setCanLoadMore] = useState(true)

    useEffect(() => {
        if (isLogged) {
            getMoreUsers()
        }
    }, [])

    useEffect(() => {
        console.log(users, usersBefore)
        if (usersBefore.length === users.length) {
            setCanLoadMore(false)
        } else {
            setCanLoadMore(true)
        }
    }, [users])

    return (
        <div className="grid gap-2">
            {typeof users === 'object' &&
                users?.map((user) => (
                    <div
                        className="flex space-x-3 items-center w-full justify-between"
                        key={user._id}
                        onClick={() => selectUser(user)}
                    >
                        <div className="flex items-center space-x-2">
                            <div
                                className="rounded-full w-[42px] h-[42px] bg-light-gray border border-black border-opacity-10"
                                style={{
                                    backgroundImage: `url(${user._profil?.avatar?.thumbnailUrl})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover',
                                }}
                                alt="Photo de profil Iléa"
                            />
                            <div className="max-w-[45vw] overflow-hidden">
                                {user._profil?.firstName} {user._profil?.lastName} {!user._profil && user.email}
                            </div>
                        </div>
                        <div
                            className={`rounded-full px-2 flex items-center h-fit ${
                                user.needContact ? `bg-red-500` : 'bg-green'
                            }`}
                        >
                            {user.needContact ? <>A contacter</> : <>Contacté</>}
                        </div>
                    </div>
                ))}

            <div className="mt-10 mx-auto">
                {canLoadMore ? (
                    <Button.Primary
                        onClick={() => {
                            setUsersBefore(users)
                            getMoreUsers()
                        }}
                    >
                        Charger plus
                    </Button.Primary>
                ) : (
                    <>Tous les utilisateurs ont été récupérés</>
                )}
            </div>
            <div className="mt-2 mx-auto">{users.length} utilisateurs</div>
        </div>
    )
}

export default Table
