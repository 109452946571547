import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
import { Button, Icon, Input, Text } from '../atoms'
import { Link } from '@nextui-org/react'
import { Users } from '../../context'

dayjs.locale(fr)

const Detail = ({ userSelected }) => {
    const { saveUser } = Users.useUsers()

    const [edited, setEdited] = useState(false)

    const [note, setNote] = useState('')
    const [needContact, setNeedContact] = useState(true)
    const [isVisible, setIsVisible] = useState(true)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (userSelected) {
            if (userSelected.note) setNote(userSelected.note)
            setNeedContact(userSelected.needContact)
            setIsVisible(userSelected.isVisible)
            setLoaded(true)
        }
    }, [userSelected])

    useEffect(() => {
        let edition = false
        if (userSelected) {
            if (userSelected.needContact !== needContact) {
                edition = true
            }
            if (note !== userSelected.note) {
                edition = true
            }
            if (userSelected.isVisible !== isVisible) {
                edition = true
            }
        }

        setEdited(edition)
    }, [note, needContact, isVisible])

    const handleSave = async () => {
        await saveUser(note, needContact, isVisible)
        setEdited(false)
    }

    return (
        userSelected &&
        loaded && (
            <div className="grid gap-8">
                <Text.Paragraph.Small className="capitalize">
                    Créé le {dayjs(userSelected.createdAt).format('dddd DD/MM/YYYY à HH:mm')}
                </Text.Paragraph.Small>
                <div className="grid gap-4">
                    {needContact && (
                        <div>
                            <Text.Paragraph.Normal className="font-bold">
                                Appel prévu le :
                                <span className="capitalize">
                                    {dayjs(userSelected.onboarding?.date).format(' dddd DD MMMM YYYY')}
                                </span>{' '}
                                à {userSelected.onboarding?.time}
                            </Text.Paragraph.Normal>
                        </div>
                    )}
                    <div className="flex items-center">
                        <Input.Checkbox
                            initialValue={isVisible}
                            setValue={(value) => {
                                console.log(value)
                                setIsVisible(value)
                            }}
                        />
                        <Text.Clickable className="ml-2">Profil visible dans les recherches</Text.Clickable>
                    </div>
                    <Link href={`mailto:${userSelected.email}`} className="flex items-center space-x-2">
                        <Icon.Email className="w-5" />
                        <Text.Paragraph.Normal>{userSelected.email}</Text.Paragraph.Normal>
                    </Link>
                    <Link
                        href={`tel:${userSelected._profil?.phoneNumber?.phone}`}
                        className="flex items-center space-x-2"
                    >
                        <Icon.Phone className="w-5" />
                        <Text.Paragraph.Normal>{userSelected._profil?.phoneNumber?.phone}</Text.Paragraph.Normal>
                    </Link>
                    <Link
                        href={`https://www.waze.com/ul?ll=${userSelected._profil?.address?.lat}%2C${userSelected._profil?.address?.lon}&navigate=yes`}
                        target="_blank"
                        className="flex items-center space-x-2"
                    >
                        <Icon.LocationMarker className="w-5" />
                        <Text.Paragraph.Normal>{userSelected._profil?.address?.formatted}</Text.Paragraph.Normal>
                    </Link>
                    <Input.Classic.TextArea
                        labelValue="Note (administrateur)"
                        value={note}
                        setValue={(value) => setNote(value)}
                    />
                    <div className="flex items-center">
                        <Input.Checkbox initialValue={!needContact} setValue={(value) => setNeedContact(!value)} />
                        <Text.Clickable className="ml-2">Contacté</Text.Clickable>
                    </div>
                    {edited && <Button.Primary onClick={() => handleSave()}>Enregistrer</Button.Primary>}
                </div>
            </div>
        )
    )
}

export default Detail
