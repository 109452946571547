import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Prestations } from '../../context'
import { Button, Text, Modal, Icon } from '../atoms'
import { useNavigate } from 'react-router-dom'

const PrestationsDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { getPrestationDetails, details, deletePrestation, getPrestations } = Prestations.usePrestations()
    const [sureToDelete, setSureToDelete] = useState(false)

    useEffect(() => {
        getPrestationDetails(id)
    }, [])

    const handleDelete = async () => {
        await deletePrestation(id)
        await getPrestations()
        navigate('/prestations')
    }

    const handleModify = () => {
        navigate(`/prestations/modify/${id}`)
    }

    const convertToMinutes = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000)
        return minutes
    }

    return (
        <div>
            <div className="flex items-center px-4" onClick={() => navigate('/prestations')}>
                <Icon.ArrowBack className="w-5 mr-2" />
                <Text.Clickable>Retour</Text.Clickable>
            </div>
            <div className="flex flex-col px-4 py-4">
                <Text.h2>Détails de la prestation</Text.h2>
                {details ? (
                    <>
                        <div className="flex flex-col py-4 space-y-3">
                            <p>Nom: {details.name}</p>
                            <p>Durée: {convertToMinutes(details.duration)} min</p>
                            <p>Type: {details.type}</p>
                        </div>
                        <div className="flex space-x-6">
                            <Button.Primary
                                onClick={async () => {
                                    handleModify()
                                }}
                            >
                                Modifier
                            </Button.Primary>
                            <Modal.default
                                isOpen={sureToDelete}
                                setIsOpen={() => setSureToDelete(false)}
                                title="Supprimer la prestation"
                                confirmButton={
                                    <Button.Red onClick={async () => await handleDelete()}>Supprimer</Button.Red>
                                }
                                backButton={
                                    <Button.Secondary onClick={() => setSureToDelete(false)}>Annuler</Button.Secondary>
                                }
                            >
                                <Text.h1 className="text-red-500">Attention</Text.h1>
                                <p>
                                    Es-tu sûr de vouloir supprimer cette prestation ? Tu ne pourras pas revenir en
                                    arrière
                                </p>
                            </Modal.default>
                            <Button.Red
                                onClick={async () => {
                                    setSureToDelete(true)
                                }}
                            >
                                Supprimer
                            </Button.Red>
                        </div>
                    </>
                ) : (
                    <p className="p-12">Chargement des détails...</p>
                )}
            </div>
        </div>
    )
}

export default PrestationsDetails
