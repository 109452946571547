import React, { useState, useEffect } from 'react'
import { Button, Input, Text, Icon } from '../atoms'
import { Prestations } from '../../context'
import { useNavigate } from 'react-router-dom'

const CreatePrestation = () => {
    const { addPrestation } = Prestations.usePrestations()
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [duration, setDuration] = useState('')
    const [durationInMinutes, setDurationInMinutes] = useState('')

    const navigate = useNavigate()

    const options = [
        { value: 'normal', label: 'Normal' },
        { value: 'forfait', label: 'Forfait' },
    ]

    useEffect(() => {
        const durationMilliseconds = convertToMilliseconds(durationInMinutes)
        setDuration(durationMilliseconds)
    }, [durationInMinutes])

    const handleAdd = async () => {
        if (name && duration && type) {
            await addPrestation(name, duration, type)
            navigate('/prestations')
        } else {
            console.error('Veuillez remplir tous les champs.')
        }
    }

    const convertToMilliseconds = (minutes) => {
        const milliseconds = minutes * 60000
        return milliseconds
    }

    return (
        <div>
            <div className="flex items-center px-4" onClick={() => navigate('/prestations')}>
                <Icon.ArrowBack className="w-5 mr-2" />
                <Text.Clickable>Retour</Text.Clickable>
            </div>
            <div className="px-4 space-y-8">
                <Text.h2>Ajouter une prestation</Text.h2>
                <form className="space-y-8">
                    <Input.Classic.Classic
                        labelValue="Nom"
                        type="text"
                        setValue={(value) => setName(value)}
                        value={name}
                    />
                    <Input.Classic.Classic
                        labelValue="Durée (min)"
                        type="number"
                        setValue={(value) => setDurationInMinutes(value)}
                        value={durationInMinutes}
                    />
                    <Input.Dropdown labelValue="Type" value={type} options={options} setValue={setType} />
                </form>
                <Button.Primary
                    onClick={async () => {
                        await handleAdd()
                    }}
                >
                    Enregistrer
                </Button.Primary>
            </div>
        </div>
    )
}

export default CreatePrestation
