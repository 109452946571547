import React, { useState } from 'react';

const Swipe = ({ checked, onChange, label }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className="hidden"
            checked={isChecked}
            onChange={handleToggle}
          />
          <div className={`toggle__line w-12 h-6 bg-gray-400 rounded-full shadow-inner ${isChecked ? 'bg-gray-950' : 'bg-gray-400'}`}></div>
          <div className={`toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0 ${isChecked ? 'translate-x-6' : 'translate-x-0'}`}></div>
        </div>
      </label>
    </div>
  );
};

export default Swipe;
