/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useEffect, useState } from 'react'

import { useRequest } from '../request'
import { useAuth } from '../auth'

const PrestationsContext = createContext({
    prestations: Array,
    details: Object,
    getPrestations: async () => {},
    getPrestationDetails: async () => {},
    savePrestation: (name, duration, type) => {},
    deletePrestation: async () => {},
    addPrestation: async (name, duration, type) => {}
})

export function PrestationsContextProvider({ children }) {
    const { handleRequest } = useRequest()
    const { token } = useAuth()

    const [prestations, setPrestations] = useState([])

    const getPrestations = async () => {
        console.log('envoye')
        setPrestations([])
        const response = await handleRequest('get', `/admin/prestations`, null, token)

        if (response?.data) {
            setPrestations(response.data.prestation)
        }
    }

    const addPrestation = async (name, duration, type) => {
        const response = await handleRequest(
            'post',
            '/admin/prestations',
            { name: name, duration: duration, type: type },
            token
        )
    
        if (response.data) {
            const newPrestation = response.data.prestation
            setPrestations([...prestations, newPrestation])
        }
    }

    const savePrestation = async (id, name, duration, type) => {
        const response = await handleRequest(
            'put',
            `admin/prestations/${id}`,
            { name: name, duration: duration, type: type },
            token
        )

        if (response.data) {
            const prestationUpdated = response.data?.prestation

            let prestationsUpdated = [...prestations]
            prestations.map((prestation, index) => {
                if (prestation._id === prestationUpdated._id) {
                    prestationsUpdated[index] = prestationUpdated
                }
            })

            setPrestations(prestationsUpdated)
        }
        return
    }

    const [details, setDetails] = useState(null)

    const getPrestationDetails = async (id) => {
        setDetails(null)
        const response = await handleRequest('get', `admin/prestations/${id}`, null, token)

        if (response?.data) {
            setDetails(response.data.prestation)
        }
    }


    const deletePrestation = async (id) => {
        await handleRequest('delete', `admin/prestations/${id}`, {}, token)
    }

    return (
        <PrestationsContext.Provider
            value={{
                prestations: prestations,
                details: details,
                getPrestations: getPrestations,
                getPrestationDetails: getPrestationDetails,
                savePrestation: savePrestation,
                deletePrestation: deletePrestation,
                addPrestation: addPrestation,
            }}
        >
            {children}
        </PrestationsContext.Provider>
    )
}

export const usePrestations = () => useContext(PrestationsContext)
