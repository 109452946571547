import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Articles } from '../../context'
import { Button, Text, Modal, Icon, Pill } from '../atoms'
import { useNavigate } from 'react-router-dom'
import { ContentRender } from '../../modules/Article/templates'

const ArticlesDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { getArticleDetails, deleteArticle, getArticles, deleteImage } = Articles.useArticles()
    const [sureToDelete, setSureToDelete] = useState(false)

    const [finalObject, setFinalObject] = useState({ title: "Titre de l'article", content: [] })

    useEffect(() => {
        const fullfillArticle = async () => {
            const result = await getArticleDetails(id)
            setFinalObject(result)
        }

        fullfillArticle()
    }, [])

    const handleDelete = async () => {
        try {
            const deleteImagesRecursively = async (contentArray) => {
                for (const item of contentArray) {
                    if (Array.isArray(item)) {
                        await deleteImagesRecursively(item)
                    } else if (item.type === 'img' && item.id) {
                        await deleteImage(item.id)
                    }
                }
            }

            if (finalObject.content && finalObject.content.length > 0) {
                await deleteImagesRecursively(finalObject.content)
            }

            if (finalObject.image && finalObject.image.id) {
                await deleteImage(finalObject.image.id)
            }

            await deleteArticle(id)
            await getArticles()
            navigate('/articles')
        } catch (error) {
            console.error("Erreur lors de la suppression de l'article ou des images associées:", error)
        }
    }

    const handleModify = () => {
        navigate(`/articles/modify/${id}`)
    }

    return (
        <div>
            <div className="flex items-center px-4" onClick={() => navigate('/articles')}>
                <Icon.ArrowBack className="w-5 mr-2" />
                <Text.Clickable>Retour</Text.Clickable>
            </div>
            <div className="flex flex-col px-4 py-4">
                {finalObject ? (
                    <>
                        <div className="flex space-x-6">
                            <Button.Primary
                                onClick={async () => {
                                    handleModify()
                                }}
                            >
                                Modifier
                            </Button.Primary>
                            <Modal.default
                                isOpen={sureToDelete}
                                setIsOpen={() => setSureToDelete(false)}
                                title="Supprimer l'article"
                                confirmButton={
                                    <Button.Red onClick={async () => await handleDelete()}>Supprimer</Button.Red>
                                }
                                backButton={
                                    <Button.Secondary onClick={() => setSureToDelete(false)}>Annuler</Button.Secondary>
                                }
                            >
                                <Text.h1 className="text-red-500">Attention</Text.h1>
                                <p>
                                    Es-tu sûr de vouloir supprimer cette article ? Tu ne pourras pas revenir en arrière
                                </p>
                            </Modal.default>
                            <Button.Red
                                onClick={async () => {
                                    setSureToDelete(true)
                                }}
                            >
                                Supprimer
                            </Button.Red>
                        </div>
                        <div className="flex flex-col w-full px-32">
                            <div className="flex items-center justify-center w-full h-40">
                                <Text.h1 className="text-violet">{finalObject.title}</Text.h1>
                            </div>
                            <ContentRender data={finalObject} />
                            {finalObject.tags && (
                                <div className="flex flex-col py-8 space-y-4">
                                    <Text.p>Tags</Text.p>
                                    <td className="space-y-3">
                                        {finalObject.tags.map((tag, index) => (
                                            <Pill.Tag key={index}>{tag}</Pill.Tag>
                                        ))}
                                    </td>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <p className="p-12">Chargement des détails...</p>
                )}
            </div>
        </div>
    )
}

export default ArticlesDetails
