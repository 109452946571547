import React from 'react'

import { Routes, Route } from 'react-router-dom'

import { ArticlesDetails } from '.'
import { ArticlesTable, CreateArticle } from '../organisms'
import { ModifyArticle } from '../molecules'

const Article = () => {

    return (
        <Routes>
            <Route path="/" element={<ArticlesTable />} />
            <Route path="/:id" element={<ArticlesDetails />} />
            <Route path="/create" element={<CreateArticle />} />
            <Route path="/modify/:id" element={<ModifyArticle />} />
        </Routes>
    )
}

export default Article
