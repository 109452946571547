import React from 'react'
import { ComposantRender } from '../molecules'

export const BlocRender = ({ bloc }) => {
    return (
        <div className='space-y-4'>
            {bloc.map((composant, index) => (
                <ComposantRender
                    key={index}
                    type={composant.type}
                    content={composant.content}
                    style={composant.style}
                />
            ))}
        </div>
    )
}
