import React, { useState } from 'react'

import { Button, Input, Text } from '../atoms'
import { useAuth } from '../../context/auth'

const Login = () => {
    const { handleLoginClassic } = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return (
        <div className="grid gap-4">
            <Text.Paragraph.Normal>Connexion</Text.Paragraph.Normal>
            <form>
                <Input.Classic.Classic
                    labelValue="Email"
                    type="email"
                    value={email}
                    setValue={(value) => setEmail(value)}
                />
                <Input.Classic.Password
                    labelValue="Mot de passe"
                    value={password}
                    setValue={(value) => setPassword(value)}
                />
            </form>
            <Button.Primary
                onClick={async () => {
                    console.log("click")
                    await handleLoginClassic(email, password)
                }}
            >
                Connexion
            </Button.Primary>
        </div>
    )
}

export default Login
