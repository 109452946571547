import bcrypt from 'bcryptjs-react'
import { setLocalStorageValue, getLocalStorageValue } from '../storage'
import { jwtDecode } from 'jwt-decode'

export const isGoodPassword = async (password, passwordHash) => {
    const comparePassword = await bcrypt.compare(password, passwordHash)

    return comparePassword
}

export const hashPassword = async (password) => {
    return await bcrypt.hash(password, 10)
}

export const persistStorage = (
    id,
    profil,
    email,
    type,
    firstName,
    lastName,
    customer,
    token,
    needNewPassword,
    password
) => {
    const jsonify = {
        id: id,
        profil: profil,
        email: email,
        type: type,
        firstName: firstName,
        lastName: lastName,
        customer: customer,
        token: token,
        needNewPassword: needNewPassword,
        password: password,
    }

    setLocalStorageValue('auth', JSON.stringify(jsonify))
}

export const isTokenExpired = () => {
    const stored = getLocalStorageValue('auth')

    const jsonify = JSON.parse(stored)

    const token = jsonify?.token

    try {
        const { exp } = jwtDecode(token)
        if (Date.now() >= exp * 1000) {
            return true
        }
    } catch (err) {
        console.log(err)
        return true
    }

    return false
}

export const getAuthStored = () => {
    const stored = getLocalStorageValue('auth')

    const jsonify = JSON.parse(stored)

    return jsonify
        ? {
              id: jsonify.id,
              profil: jsonify.profil,
              email: jsonify.email,
              type: jsonify.type,
              firstName: jsonify.firstName,
              lastName: jsonify.lastName,
              customer: jsonify.customer,
              token: jsonify.token,
              needNewPassword: jsonify.needNewPassword,
              password: jsonify.password,
          }
        : null
}
