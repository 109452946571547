import { Button } from '@nextui-org/react'
import React from 'react'
import { Text } from '../atoms'

const CardButton = ({ children, onClick }) => {
    return (
        <Button className="flex p-6 rounded-xl bg-purple" onClick={onClick ? onClick : () => {}}>
            <Text.Clickable>{children}</Text.Clickable>
        </Button>
    )
}

export default CardButton
