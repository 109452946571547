import React, { useState } from 'react'
import { useInput } from '@nextui-org/react'

import { Icon } from '..'

const CloseFilledIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.6496 14.0003L21.8248 7.82517C22.281 7.369 22.281 6.63167 21.8248 6.1755C21.3686 5.71933 20.6313 5.71933 20.1751 6.1755L14 12.3506L7.8248 6.1755C7.36863 5.71933 6.6313 5.71933 6.17513 6.1755C5.71897 6.63167 5.71897 7.369 6.17513 7.82517L12.3503 14.0003L6.17513 20.1756C5.71897 20.6316 5.71897 21.3689 6.17513 21.8251C6.40263 22.0526 6.7013 22.1669 6.99997 22.1669C7.29863 22.1669 7.5973 22.0526 7.8248 21.8251L14 15.6501L20.1751 21.8251C20.4026 22.0526 20.7013 22.1669 21 22.1669C21.2986 22.1669 21.5973 22.0526 21.8248 21.8251C22.281 21.3689 22.281 20.6316 21.8248 20.1756L15.6496 14.0003Z"
            fill="#1C1C1C"
        />
    </svg>
)

const Classic = ({ labelValue, placeholder, value, setValue }) => {
    const {
        Component,
        label,
        domRef,
        description,
        isClearable,
        startContent,
        endContent,
        shouldLabelBeOutside,
        shouldLabelBeInside,
        errorMessage,
        getBaseProps,
        getLabelProps,
        getInputProps,
        getInnerWrapperProps,
        getInputWrapperProps,
        getDescriptionProps,
        getErrorMessageProps,
        getClearButtonProps,
    } = useInput({
        value: value,
        isClearable: 'true',
        labelPlacement: 'outside',
        placeholder: placeholder,
        label: labelValue,
        classNames: {
            label: ['relative', 'grid', 'origin-left', 'translate-y-0', 'mb-2', 'text-lg', 'font-medium'],
            input: ['text-lg', 'font-medium'],
            inputWrapper: ['grid', 'gap-0', 'border', 'border-black', 'rounded-full', 'px-6', 'py-3'],
            clearButton: ['right-6', 'text-black'],
        },
        onChange: (e) => {
            setValue(e.target.value)
        },
        onClear: () => {
            setValue()
        },
    })

    const labelContent = <label {...getLabelProps()}>{label}</label>

    const end = React.useMemo(() => {
        if (isClearable) {
            return <span {...getClearButtonProps()}>{<CloseFilledIcon />}</span>
        }

        return endContent
    }, [isClearable, getClearButtonProps])

    const innerWrapper = React.useMemo(() => {
        if (startContent || end) {
            return (
                <div {...getInnerWrapperProps()}>
                    {startContent}
                    <input {...getInputProps()} />
                    {end}
                </div>
            )
        }

        return <input {...getInputProps()} />
    }, [startContent, end, getInputProps, getInnerWrapperProps])

    return (
        <div>
            <Component {...getBaseProps()}>
                {shouldLabelBeOutside ? labelContent : null}
                <div
                    {...getInputWrapperProps()}
                    role="button"
                    onClick={() => {
                        domRef.current?.focus()
                    }}
                >
                    {shouldLabelBeInside ? labelContent : null}
                    {innerWrapper}
                </div>
                {description && <div {...getDescriptionProps()}>{description}</div>}
                {errorMessage && <div {...getErrorMessageProps()}>{errorMessage}</div>}
            </Component>
        </div>
    )
}

const Password = ({ labelValue, placeholder, value, setValue }) => {
    const [type, setType] = useState('password')

    return (
        <div className="relative grid gap-1">
            <label className="ml-2 text-lg font-medium">{labelValue}</label>
            <div className="flex justify-between w-full px-6 py-3 border border-black rounded-full items-flex">
                <input
                    name={labelValue}
                    className="text-lg font-normal focus:border-none"
                    label={labelValue}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type={type || 'text'}
                />
                <div
                    className="absolute bg-white right-5 bottom-3"
                    onClick={() => setType(type === 'password' ? 'text' : 'password')}
                >
                    {type === 'password' ? <Icon.EyeClose className="w-6" /> : <Icon.EyeOpen className="w-5" />}
                </div>
            </div>
        </div>
    )
}

const TextArea = ({ labelValue, placeholder, value, setValue, height }) => {
    return (
        <div className="grid gap-2">
            <p className="text-lg font-medium">{labelValue}</p>
            <textarea
                className={`px-6 py-3 border border-black rounded-lg min-h-[200px] ${
                    height ? height : 'h-[200px]'
                } overflow-y-auto`}
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    )
}

export { Classic, Password, TextArea }
