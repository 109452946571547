import React from 'react'
import { BlocRender } from '../organisms'

export const ContentRender = ({ data }) => {
    return (
        <div className='space-y-10'>
            {data.content.map((bloc, blocIndex) => (
                <BlocRender key={blocIndex} bloc={bloc} />
            ))}
        </div>
    )
}
