import React, { useState } from 'react'

import { Navbar } from '../molecules'
import { Detail, Usage } from '../organisms'

import { Users } from '../../context'
import { Button, Modal, Text } from '../atoms'

const UserDetails = () => {
    const { userSelected, selectUser, deleteUserSelected } = Users.useUsers()

    const [sureToDelete, setSureToDelete] = useState(false)

    return (
        <div className="relative">
            <Navbar.User
                avatar={userSelected?._profil?.avatar?.thumbnailUrl}
                firstName={userSelected?._profil?.firstName}
                lastName={userSelected?._profil?.lastName}
                goBack={() => selectUser(null)}
            />
            <div className="grid gap-8 p-3">
                <Detail userSelected={userSelected} />
                <Usage lastUseAt={userSelected?.lastUseAt ? userSelected.lastUseAt : userSelected?.updatedAt} />
                <Modal.default
                    isOpen={sureToDelete}
                    setIsOpen={() => setSureToDelete(false)}
                    title="Supprimé utilisateur"
                    confirmButton={<Button.Red onClick={() => deleteUserSelected()}>Supprimer</Button.Red>}
                    backButton={<Button.Secondary onClick={() => setSureToDelete(false)}>Annuler</Button.Secondary>}
                >
                    <Text.h1 className="text-red-500">Attention cette action est irréversible</Text.h1>
                    <p>Es-tu sûr de vouloir supprimé cet utilisateur ? Tu ne pourras pas revenir en arrière</p>
                </Modal.default>
                <Button.Red onClick={() => setSureToDelete(true)}>Supprimer</Button.Red>
            </div>
        </div>
    )
}

export default UserDetails
