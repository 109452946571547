import React from 'react'

import { Routes, Route } from 'react-router-dom'

import { PrestationsDetails} from '.'
import { PrestationsTable } from '../organisms'
import { CreatePrestation, ModifyPrestation } from '../molecules'

const Prestation = () => {

    return (
        <Routes>
            <Route path="/" element={<PrestationsTable />} />
            <Route path="/:id" element={<PrestationsDetails />} />
            <Route path="/create" element={<CreatePrestation />} />
            <Route path="/modify/:id" element={<ModifyPrestation />} />
        </Routes>
    )
}

export default Prestation
