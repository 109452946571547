import React, { useEffect, useState } from 'react'

import { NextUIProvider } from '@nextui-org/react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { Home, User, Prestation, Article } from './components/templates'
import { Request, Auth, Users, Prestations, Articles } from './context'

import { useAuth } from './context/auth'
import { Login, Navbar } from './components/molecules'

const Router = () => {
    const { isLogged, id, token } = useAuth()

    const [logged, setLogged] = useState(false)

    useEffect(() => {
        setLogged(isLogged())
    }, [id, token])

    return (
        <>
            <Navbar.Home />
            <Routes>
                {logged ? (
                    <>
                        <Route path="/" element={<Home />} />
                        <Route path="/user/*" element={<User />} />
                        <Route path="/prestations/*" element={<Prestation />} />
                        <Route path="/articles/*" element={<Article />} />

                    </>
                ) : (
                    <Route path="/" element={<Login />} />
                )}
            </Routes>
        </>
    )
}

const App = () => {
    const navigate = useNavigate()

    return (
        <NextUIProvider navigate={navigate}>
            <Request.RequestContextProvider>
                <Auth.AuthContextProvider>
                    <Users.UsersContextProvider>
                        <Prestations.PrestationsContextProvider>
                            <Articles.ArticlesContextProvider>
                                <Router />
                            </Articles.ArticlesContextProvider>
                        </Prestations.PrestationsContextProvider>
                    </Users.UsersContextProvider>
                </Auth.AuthContextProvider>
            </Request.RequestContextProvider>
        </NextUIProvider>
    )
}

export default App
