import React from 'react'

import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/modal'
import { Button, Icon, Text } from '.'

const ModalDefault = ({ title, isOpen, setIsOpen, children, confirmButton, backButton, backMethod }) => {
    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={() => setIsOpen(!isOpen)}
            classNames={{
                backdrop: 'fixed bg-white h-[100vh] bg-opacity-50 top-0 w-full',
                base: 'fixed h-[fit] bg-white ring-1 ring-black ring-inset rounded-lg bottom-[-5px] w-[100vw]',
                header: 'py-6 text-lg font-bold',
                body: 'w-100% max-h-[60vmax] overflow-y-scroll mx-auto border rounded',
            }}
            closeButton={<span className="absolute text-white bg-black"></span>}
            onClose={() => setIsOpen(false)}
        >
            <ModalContent>
                <ModalHeader className="grid">
                    <div className="p-1" onTouchEnd={() => setIsOpen(false)}>
                        <div className="w-10 h-1 mx-auto mb-4 bg-gray-300 rounded"></div>
                    </div>
                    <Text.h2 className="w-full text-center">{title}</Text.h2>
                </ModalHeader>
                <ModalBody className="w-full border-0 min-[450px]:px-6 min-[370px]:px-4 px-2">{children}</ModalBody>
                <ModalFooter className="grid justify-center min-[450px]:px-6 min-[370px]:px-4 px-1">
                    <div className="flex space-x-1">
                        {backButton ? (
                            <div>{backButton}</div>
                        ) : (
                            <Button.Secondary
                                onClick={backMethod ? () => backMethod() : () => setIsOpen(false)}
                                iconLeft={<Icon.ArrowBack className="w-5" />}
                            >
                                Retour
                            </Button.Secondary>
                        )}
                        {confirmButton}
                    </div>
                    <div className="p-1" onTouchEnd={() => setIsOpen(false)}>
                        <div className="w-[160px] h-1 mx-auto mb-4 bg-gray-300 rounded"></div>
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalDefault
