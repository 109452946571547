import React, { useEffect } from 'react'
import { Auth, Prestations } from '../../context'
import { Button, Text } from '../atoms'
import { useNavigate } from 'react-router-dom'

const PrestationsTable = () => {
    const navigate = useNavigate()
    const { isLogged } = Auth.useAuth()
    const { prestations, getPrestations } = Prestations.usePrestations()

    useEffect(() => {
        if (isLogged) {
            getPrestations()
        }
    }, [])

    const handleDetails = async (id) => {
        navigate(`/prestations/${id}`)
    }

    const convertToMinutes = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000)
        return minutes
    }

    return (
        <div>
            <div className="flex justify-center">
                <Button.Primary
                    onClick={async () => {
                        navigate('/prestations/create')
                    }}
                >
                    Créer une prestation
                </Button.Primary>
            </div>
            <div className="py-6 ml-12">
                <Text.h2>Liste des prestations</Text.h2>
            </div>
            {prestations && typeof prestations === 'object' && prestations.length > 0 ? (
                <table className="w-full divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-8 text-left">
                                Nom
                            </th>
                            <th scope="col" className="px-6 py-8 text-left">
                                Durée
                            </th>
                            <th scope="col" className="px-6 py-8 text-left">
                                Type
                            </th>
                            <th scope="col" className=""></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y">
                        {prestations.map((prestation) => (
                            <tr key={prestation._id}>
                                <td className="px-6 py-8">{prestation.name}</td>
                                <td className="px-6 py-8">{convertToMinutes(prestation.duration)} min</td>
                                <td className="px-6 py-8">{prestation.type}</td>
                                <td className="flex justify-center py-8">
                                    <Button.Primary onClick={() => handleDetails(prestation._id)}>
                                        Voir plus
                                    </Button.Primary>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="p-12">Chargement des prestations...</p>
            )}
        </div>
    )
}

export default PrestationsTable
