import React, { useState, useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Text, Icon, Button, Pill } from '../atoms'
import { Articles } from '../../context'
import { useNavigate, useParams } from 'react-router-dom'

const generateUniqueId = () => {
    const timestamp = new Date().getTime().toString()
    return timestamp
}

const items = [
    { id: '1', content: 'Text', thumb: '/img/text.png', type: 'p' },
    { id: '2', content: 'Image', thumb: '/img/image.png', type: 'img' },
]

function Article() {
    const navigate = useNavigate()
    const { id } = useParams()

    const inputRef = useRef(null)
    const { saveArticle, getArticleDetails, getArticles, deleteImage, uploadImage } = Articles.useArticles()
    const [title, setTitle] = useState('')
    const [content, setContent] = useState([])
    const [seo, setSeo] = useState('')
    const [tags, setTags] = useState([])
    const [image, setImage] = useState()

    const [contentBlocksCount, setContentBlocksCount] = useState(1)
    const [activeItem, setActiveItem] = useState({
        id: '',
        content: '',
        type: 'p',
        style: { color: '#000000', fontStyle: 'normal', textDecoration: 'none', textAlign: 'left' },
    })
    const [textType, setTextType] = useState('p')
    const [textAlign, setTextAlign] = useState('left')
    const [newTag, setNewTag] = useState('')

    const [activeTab, setActiveTab] = useState('title')
    const [fieldsError, setFieldsError] = useState('')

    useEffect(() => {
        const fullfillArticle = async () => {
            const result = await getArticleDetails(id)
            setTitle(result?.title)
            setContent(result?.content)
            setSeo(result?.seo)
            setTags(result?.tags)
            setContentBlocksCount(result?.content.length)
            setImage({ id: result?.image.id, url: result?.image.url })
            console.log(result)
        }

        fullfillArticle()
    }, [])

    const handleAdd = async () => {
        let errorFields = ''

        if (!title) {
            errorFields += 'Titre manquant. '
        }
        if (content.length == 0) {
            errorFields += 'Contenu manquant. '
        }
        if (!seo) {
            errorFields += 'SEO manquant. '
        }
        if (tags.length === 0) {
            errorFields += 'Tags manquants. '
        }
        if (!image.id || !image.url) {
            errorFields += 'Image de présentation manquante.\n'
        }

        if (errorFields) {
            setFieldsError(errorFields)
        } else {
            saveArticle(id, title, content, seo, tags, image)
            await getArticles()
            navigate('/articles')
        }
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return

        const { source, destination } = result

        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return
        }

        if (source.droppableId === 'items' && destination.droppableId.startsWith('content')) {
            const { content, type } = items[source.index]
            const newItem = {
                id: generateUniqueId(),
                content,
                type,
                style: { color: '#000000', fontStyle: 'normal', textDecoration: 'none', textAlign: 'left' },
            }
            const blockIndex = parseInt(destination.droppableId.replace('content', ''))
            setContent((prevState) => {
                const newState = [...prevState]
                newState[blockIndex] = [...(newState[blockIndex] || []), newItem]
                return newState
            })
        }

        if (source.droppableId.startsWith('content') && destination.droppableId.startsWith('content')) {
            const sourceBlockIndex = parseInt(source.droppableId.replace('content', ''))
            const destinationBlockIndex = parseInt(destination.droppableId.replace('content', ''))
            const item = content[sourceBlockIndex][source.index]
            setContent((prevState) => {
                const newState = [...prevState]
                newState[sourceBlockIndex] = newState[sourceBlockIndex].filter((_id, index) => index !== source.index)
                newState[destinationBlockIndex].splice(destination.index, 0, item)
                return newState
            })
        }
    }

    const addContentBlock = () => {
        setContentBlocksCount((prevCount) => prevCount + 1)
        setContent((prevState) => [...prevState, []])
    }

    const handleItemClick = (id, content, type, style = {}) => {
        const defaultStyle =
            type === 'img' ? {} : { color: '#000000', fontStyle: 'normal', textDecoration: 'none', textAlign: 'left' }
        setActiveItem({
            id,
            content,
            type,
            style: { ...defaultStyle, ...style },
        })
        if (type !== 'img') {
            setTextType(type)
            setTextAlign(style.textAlign || 'left')
        }
    }

    const handleContentChange = (event) => {
        const newContent = event.target.value
        setActiveItem((prevState) => ({ ...prevState, content: newContent }))
        setContent((prevState) =>
            prevState.map((block) =>
                block.map((item) => (item.id === activeItem.id ? { ...item, content: newContent } : item))
            )
        )
    }

    const handleTypeChange = (event) => {
        const newType = event.target.value
        setTextType(newType)
        setActiveItem((prevState) => ({ ...prevState, type: newType }))
        setContent((prevState) =>
            prevState.map((block) =>
                block.map((item) => (item.id === activeItem.id ? { ...item, type: newType } : item))
            )
        )
    }

    const handleColorChange = (event) => {
        const newColor = event.target.value
        setActiveItem((prevState) => ({ ...prevState, style: { ...prevState.style, color: newColor } }))
        setContent((prevState) =>
            prevState.map((block) =>
                block.map((item) =>
                    item.id === activeItem.id ? { ...item, style: { ...item.style, color: newColor } } : item
                )
            )
        )
    }

    const handleTextAlignChange = () => {
        const newTextAlign =
            textAlign === 'left'
                ? 'center'
                : textAlign === 'center'
                ? 'right'
                : textAlign === 'right'
                ? 'justify'
                : 'left'
        setTextAlign(newTextAlign)
        setActiveItem((prevState) => ({ ...prevState, style: { ...prevState.style, textAlign: newTextAlign } }))
        setContent((prevState) =>
            prevState.map((block) =>
                block.map((item) =>
                    item.id === activeItem.id ? { ...item, style: { ...item.style, textAlign: newTextAlign } } : item
                )
            )
        )
    }

    const handleFontStyleChange = () => {
        const newFontStyle = activeItem.style.fontStyle === 'normal' ? 'italic' : 'normal'
        setActiveItem((prevState) => ({ ...prevState, style: { ...prevState.style, fontStyle: newFontStyle } }))
        setContent((prevState) =>
            prevState.map((block) =>
                block.map((item) =>
                    item.id === activeItem.id ? { ...item, style: { ...item.style, fontStyle: newFontStyle } } : item
                )
            )
        )
    }

    const handleTextDecorationChange = () => {
        const newTextDecoration = activeItem.style.textDecoration === 'none' ? 'underline' : 'none'
        setActiveItem((prevState) => ({
            ...prevState,
            style: { ...prevState.style, textDecoration: newTextDecoration },
        }))
        setContent((prevState) =>
            prevState.map((block) =>
                block.map((item) =>
                    item.id === activeItem.id
                        ? { ...item, style: { ...item.style, textDecoration: newTextDecoration } }
                        : item
                )
            )
        )
    }

    const handleDeleteItem = async () => {
        setContent((prevState) =>
            prevState.map((block) =>
                block.filter((item) => {
                    if (item.id === activeItem.id) {
                        if (item.type === 'img' && activeItem.type !== 'img' && activeItem.content !== 'Image') {
                            deleteImage(item.id)
                            return false
                        }
                        return false
                    }
                    return true
                })
            )
        )
        if (activeItem.type === 'img' && activeItem.content !== 'Image') {
            await deleteImage(activeItem.id)
        }

        setActiveItem({
            id: '',
            content: '',
            type: 'p',
            style: { color: '#000000', fontStyle: 'normal', textDecoration: 'none', textAlign: 'left' },
        })
    }

    const handleTabSwitch = (tab) => {
        setActiveTab(tab)
    }

    const [editingTagIndex, setEditingTagIndex] = useState(null)

    const addTag = () => {
        if (newTag.trim() !== '') {
            setTags([...tags, newTag.trim()])
            setNewTag('')
        }
    }

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_id, index) => index !== indexToRemove))
    }

    const handleTagClick = (index) => {
        setEditingTagIndex(index)
    }

    const handleTagEdit = (index, newTagValue) => {
        const updatedTags = [...tags]
        updatedTags[index] = newTagValue
        setTags(updatedTags)
    }

    const renderText = (text, type, style) => {
        const Component = Text[type] || Text.p
        const textSplit = text.split('\n')
        return (
            <Component className="w-full" style={style}>
                {textSplit.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </Component>
        )
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0]
        try {
            if (!file) {
                throw new Error('No file selected.')
            }

            const fileName = file.name
            const imageData = await uploadImage(file, fileName)

            setContent((prevContent) =>
                prevContent.map((block) =>
                    block.map((item) =>
                        item.content === 'Image' && item.type === 'img'
                            ? { ...item, content: imageData.url, id: imageData._id, style: {} }
                            : item
                    )
                )
            )
            setActiveItem({
                id: '',
                content: '',
                type: 'p',
                style: { color: '#000000', fontStyle: 'normal', textDecoration: 'none', textAlign: 'left' },
            })
        } catch (error) {
            console.error(`Error handling image change for file '${file ? file.name : 'unknown'}':`, error)
        }
    }

    const handleImagePresentationUpload = async (event) => {
        const file = event.target.files[0]
        try {
            if (!file) {
                throw new Error('No file selected.')
            }

            const fileName = file.name
            const imageData = await uploadImage(file, fileName)

            console.log(imageData)
            setImage({ id: imageData._id, url: imageData.url })
        } catch (error) {
            console.error(`Error handling image change for file '${file ? file.name : 'unknown'}':`, error)
        }
    }

    const handleDeleteImagePresentation = async () => {
        if (image.id) {
            try {
                await deleteImage(image.id)
                setImage({ id: '', url: '' })
            } catch (error) {
                console.error(`Error deleting image with id '${image.id}':`, error)
            }
        } else {
            console.error('No image to delete.')
        }
    }

    return (
        <div>
            <header className="flex flex-col items-center justify-center mt-6">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <div className="flex justify-start w-11/12">
                        <Droppable droppableId="items" isDropDisabled={true}>
                            {(provided) => (
                                <div
                                    className="flex flex-col w-1/4"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <div className="flex flex-col items-center justify-around w-full pb-2 md:flex-row">
                                        <Button.Primary onClick={handleAdd}>Enregistrer</Button.Primary>
                                    </div>
                                    {fieldsError && (
                                        <Text.p className="flex flex-col w-full text-red-700">
                                            {fieldsError
                                                .split('\n')
                                                .filter((line) => line)
                                                .map((line, index) => (
                                                    <span key={index}>
                                                        {line}
                                                        <br />
                                                    </span>
                                                ))}
                                        </Text.p>
                                    )}
                                    <div className="flex flex-col mt-4 rounded-lg bg-violet">
                                        <Text.h3 className="flex justify-center py-4 text-center text-white">
                                            Contenu
                                        </Text.h3>
                                        <ul className="flex flex-col items-center justify-around p-3 list-none md:flex-row min-h-8">
                                            {items.map(({ id, content, thumb }, index) => (
                                                <Draggable key={id} draggableId={id.toString()} index={index}>
                                                    {(provided) => (
                                                        <li
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="flex flex-col items-center justify-center w-20 h-20 mb-2 space-y-2 bg-white border rounded-lg"
                                                        >
                                                            <div className="flex w-5 h-5 overflow-hidden bg-balck">
                                                                <img
                                                                    src={thumb}
                                                                    alt={`${content} Thumb`}
                                                                    className="flex w-full h-auto"
                                                                />
                                                            </div>
                                                            {renderText(content, 'p', {
                                                                color: '#000000',
                                                                textAlign: 'center',
                                                            })}
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    </div>
                                    <div className="flex flex-col items-center justify-center py-4 mt-4 rounded-lg bg-violet">
                                        <div className="flex flex-col justify-around w-full px-4 mb-4 space-y-2 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
                                            <button
                                                onClick={() => handleTabSwitch('title')}
                                                className={`flex justify-center border-black border-2 p-2 rounded-lg ${
                                                    activeTab === 'title'
                                                        ? 'text-black bg-purple'
                                                        : 'text-black bg-white'
                                                }`}
                                            >
                                                <Text.p>Titre</Text.p>
                                            </button>
                                            <button
                                                onClick={() => handleTabSwitch('seo')}
                                                className={`flex justify-center border-black border-2 p-2 rounded-lg ${
                                                    activeTab === 'seo' ? 'text-black bg-purple' : 'text-black bg-white'
                                                }`}
                                            >
                                                <Text.p>SEO</Text.p>
                                            </button>
                                            <button
                                                onClick={() => handleTabSwitch('tags')}
                                                className={`flex justify-center border-black border-2 p-2 rounded-lg ${
                                                    activeTab === 'tags'
                                                        ? 'text-black bg-purple'
                                                        : 'text-black bg-white'
                                                }`}
                                            >
                                                <Text.p>Tags</Text.p>
                                            </button>
                                            <button
                                                onClick={() => handleTabSwitch('image')}
                                                className={`flex justify-center border-black border-2 p-2 rounded-lg ${
                                                    activeTab === 'image'
                                                        ? 'text-black bg-purple'
                                                        : 'text-black bg-white'
                                                }`}
                                            >
                                                <Text.p>Image</Text.p>
                                            </button>
                                        </div>

                                        {activeTab === 'title' && (
                                            <input
                                                className="flex w-10/12 p-4 mb-4 border-2 border-black rounded-xl"
                                                type="text"
                                                ref={inputRef}
                                                onChange={(e) => setTitle(e.target.value)}
                                                placeholder="Titre de l'article"
                                                value={title}
                                            />
                                        )}
                                        {activeTab === 'seo' && (
                                            <textarea
                                                className="flex w-10/12 p-4 mb-4 border-2 border-black rounded-xl"
                                                type="text"
                                                value={seo}
                                                onChange={(e) => setSeo(e.target.value)}
                                                placeholder="Meta description"
                                            />
                                        )}
                                        {activeTab === 'tags' && (
                                            <div className="flex flex-col items-center justify-center">
                                                {tags.map((tag, index) => (
                                                    <div key={index} className="flex items-center py-1">
                                                        {editingTagIndex === index ? (
                                                            <>
                                                                <input
                                                                    className="inline-block px-3 py-1 mr-2 text-sm text-gray-700 bg-white rounded-full"
                                                                    type="text"
                                                                    value={tag}
                                                                    onChange={(e) =>
                                                                        handleTagEdit(index, e.target.value)
                                                                    }
                                                                    onBlur={() => setEditingTagIndex(null)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button onClick={() => handleTagClick(index)}>
                                                                    <Pill.Tag className="bg-white">{tag}</Pill.Tag>
                                                                </button>
                                                                <button
                                                                    onClick={() => removeTag(index)}
                                                                    className="flex items-center justify-center w-8 h-8 ml-2 text-white bg-red-600 rounded-full"
                                                                >
                                                                    -
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                                <input
                                                    className="flex w-10/12 p-4 my-4 border-2 border-black rounded-xl"
                                                    type="text"
                                                    value={newTag}
                                                    placeholder="Entrez un tag"
                                                    onChange={(e) => setNewTag(e.target.value)}
                                                />
                                                <Button.Primary onClick={addTag}>Ajouter</Button.Primary>
                                            </div>
                                        )}
                                        {activeTab === 'image' && (
                                            <div className="flex flex-col items-center w-10/12 my-4 space-y-4">
                                                {image.id ? (
                                                    <>
                                                        <img src={image.url} className="w-full" />
                                                        <button
                                                            onClick={handleDeleteImagePresentation}
                                                            className="p-4 text-white bg-red-500 rounded-lg"
                                                        >
                                                            Supprimer
                                                        </button>
                                                    </>
                                                ) : (
                                                    <input
                                                        className="flex w-10/12 border-2 border-black rounded-xl"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => handleImagePresentationUpload(event)}
                                                        ref={inputRef}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {activeItem.id && (
                                        <>
                                            {activeItem.type === 'img' ? (
                                                <div className="flex mt-4 bg-violet rounded-lg h-[200px] flex-col items-center justify-around mb-4">
                                                    <Text.h3 className="flex py-4 text-white">Style</Text.h3>
                                                    {activeItem.content === 'Image' && (
                                                        <input
                                                            className="flex w-10/12 border-2 border-black rounded-xl"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(event) => handleImageUpload(event)}
                                                            ref={inputRef}
                                                        />
                                                    )}

                                                    <button
                                                        onClick={handleDeleteItem}
                                                        disabled={!activeItem.id}
                                                        className="p-4 text-white bg-red-500 rounded-lg"
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="flex mt-4 bg-violet rounded-lg h-[400px] flex-col items-center justify-around mb-4">
                                                    <Text.h3 className="flex py-4 text-white">Style</Text.h3>
                                                    <textarea
                                                        className="flex w-10/12 p-4 border-2 border-black rounded-xl"
                                                        type="text"
                                                        ref={inputRef}
                                                        value={activeItem.content}
                                                        onChange={handleContentChange}
                                                        placeholder="Edit item content"
                                                        onKeyDown={(event) => {
                                                            if (event.code === 'Enter') {
                                                                event.preventDefault()
                                                                const newContent = event.target.value + '\n'
                                                                setActiveItem((prevState) => ({
                                                                    ...prevState,
                                                                    content: newContent,
                                                                }))
                                                                setContent((prevState) =>
                                                                    prevState.map((block) =>
                                                                        block.map((item) =>
                                                                            item.id === activeItem.id
                                                                                ? { ...item, content: newContent }
                                                                                : item
                                                                        )
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                    />

                                                    <select
                                                        className="flex w-10/12 p-4 border-2 border-black rounded-xl"
                                                        value={textType}
                                                        onChange={handleTypeChange}
                                                    >
                                                        <option value="h2">Titre</option>
                                                        <option value="h3">Sous titre</option>
                                                        <option value="p">Paragraphe</option>
                                                    </select>
                                                    <div className="flex justify-center w-10/12 space-x-2">
                                                        <div className="relative">
                                                            <input
                                                                type="color"
                                                                value={activeItem.style.color}
                                                                onChange={handleColorChange}
                                                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                            />
                                                            <div className="flex items-center justify-center w-10 h-10 bg-white border-2 border-black rounded-xl">
                                                                <div
                                                                    className="w-4 h-4 rounded-full"
                                                                    style={{ backgroundColor: activeItem.style.color }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={handleFontStyleChange}
                                                            className={`flex items-center justify-center rounded-xl w-10 h-10 italic border-black border-2 ${
                                                                activeItem.style.fontStyle === 'italic'
                                                                    ? 'text-white bg-black'
                                                                    : 'bg-white text-black'
                                                            }`}
                                                        >
                                                            I
                                                        </button>
                                                        <button
                                                            onClick={handleTextDecorationChange}
                                                            className={`flex items-center justify-center rounded-xl w-10 h-10 underline border-black border-2 ${
                                                                activeItem.style.textDecoration === 'underline'
                                                                    ? 'text-white bg-black'
                                                                    : 'bg-white text-black'
                                                            }`}
                                                        >
                                                            U
                                                        </button>

                                                        <button
                                                            onClick={handleTextAlignChange}
                                                            className={`flex items-center justify-center rounded-xl w-10 h-10 border-black border-2 bg-white`}
                                                        >
                                                            {textAlign === 'left' ? (
                                                                <Icon.TextLeft className="w-6 h-6 text-black" />
                                                            ) : textAlign === 'center' ? (
                                                                <Icon.TextCenter className="w-6 h-6 text-black" />
                                                            ) : textAlign === 'right' ? (
                                                                <Icon.TextRight className="w-6 h-6 text-black" />
                                                            ) : (
                                                                <Icon.TextJustify className="w-6 h-6 text-black" />
                                                            )}
                                                        </button>
                                                    </div>

                                                    <button
                                                        onClick={handleDeleteItem}
                                                        disabled={!activeItem.id}
                                                        className="p-4 text-white bg-red-500 rounded-lg"
                                                    >
                                                        Supprimer
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </Droppable>
                        <div className="flex flex-col items-center w-full">
                            <div className="flex flex-col w-10/12 space-y-4">
                                <Text.h1 className="my-4 text-center min-h-12 text-violet">{title}</Text.h1>
                                {[...Array(contentBlocksCount)].map((_id, blockIndex) => (
                                    <Droppable key={blockIndex} droppableId={`content${blockIndex}`}>
                                        {(provided) => (
                                            <div>
                                                <ul
                                                    className="flex flex-col p-3 space-y-2 list-none border-2 rounded-lg min-h-16 border-violet"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {content[blockIndex]?.map(({ id, content, type, style }, index) => (
                                                        <Draggable key={id} draggableId={id.toString()} index={index}>
                                                            {(provided) => (
                                                                <li
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={`flex items-center p-2 border rounded-lg bg-white ${
                                                                        activeItem.id === id
                                                                            ? 'border-2 border-red-500'
                                                                            : 'border-gray-300'
                                                                    }`}
                                                                    onClick={() =>
                                                                        handleItemClick(id, content, type, style)
                                                                    }
                                                                >
                                                                    {type === 'p' || type === 'h2' || type === 'h3' ? (
                                                                        renderText(content, type, style)
                                                                    ) : type === 'img' ? (
                                                                        <img
                                                                            src={content}
                                                                            alt="Image"
                                                                            className="w-full h-auto"
                                                                        />
                                                                    ) : null}
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    ))}

                                                    {provided.placeholder}
                                                </ul>
                                            </div>
                                        )}
                                    </Droppable>
                                ))}
                            </div>
                            <button
                                onClick={addContentBlock}
                                className="flex items-center justify-center w-10 h-10 mt-6 text-2xl text-white rounded-full bg-violet"
                            >
                                +
                            </button>
                        </div>
                    </div>
                </DragDropContext>
            </header>
        </div>
    )
}

export default Article
