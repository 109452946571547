import React, { useState, useEffect } from 'react'
import { Button, Input, Text, Icon } from '../atoms'
import { Prestations } from '../../context'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const ModifyPrestation = () => {
    const { id } = useParams()
    const { getPrestationDetails, savePrestation, details, getPrestations } = Prestations.usePrestations()
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [duration, setDuration] = useState(0)
    const [durationInMinutes, setDurationInMinutes] = useState(0)

    const navigate = useNavigate()

    useEffect(() => {
        if (details) {
            setType(details.type)
            setName(details.name)
            setDuration(details.duration)
            setDurationInMinutes(convertToMinutes(details.duration))
        } else {
            getPrestationDetails(id)
        }
    }, [details, id, getPrestationDetails])

    useEffect(() => {
        const durationMilliseconds = convertToMilliseconds(durationInMinutes)
        setDuration(durationMilliseconds)
    }, [durationInMinutes])

    const options = [
        { value: 'normal', label: 'Normal' },
        { value: 'forfait', label: 'Forfait' },
    ]

    const handleModify = async () => {
        if (name && duration && type) {
            savePrestation(id, name, duration, type)
            await getPrestations()
            navigate('/prestations')
        } else {
            console.error('Veuillez remplir tous les champs.')
        }
    }

    const convertToMinutes = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000)
        return minutes
    }

    const convertToMilliseconds = (minutes) => {
        const milliseconds = minutes * 60000
        return milliseconds
    }

    return (
        <div>
            <div className="flex items-center px-4 mb-8" onClick={() => navigate('/prestations')}>
                <Icon.ArrowBack className="w-5 mr-2" />
                <Text.Clickable>Retour</Text.Clickable>
            </div>
            <div className="px-4 space-y-8">
                <Text.h2>Modifier la prestation</Text.h2>
                <form className="space-y-4">
                    <Input.Classic.Classic
                        labelValue="Nom"
                        type="text"
                        setValue={(value) => setName(value)}
                        value={name}
                    />
                    <Input.Classic.Classic
                        labelValue="Durée (min)"
                        type="number"
                        setValue={(value) => setDurationInMinutes(value)}
                        value={durationInMinutes}
                    />
                    <Input.Dropdown labelValue="Type" value={type} options={options} setValue={setType} />
                </form>
                <Button.Primary
                    onClick={async () => {
                        await handleModify()
                    }}
                >
                    Enregistrer
                </Button.Primary>
            </div>
        </div>
    )
}

export default ModifyPrestation
